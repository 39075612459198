<template>
  <section id="pricing-plan">
    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col v-if="plan.id" :md="pricingCols">
            <b-card align="center">
              <h3>{{ plan.title }}</h3>
              <b-card-text>
                {{ plan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">
                    €
                  </sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >
                    {{ plan.subscriptionAmount }}
                  </span>
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                  >
                    /{{ $t('year') }}
                  </sub>
                </div>
                <div v-if="plan.balance > 0" class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">
                    €
                  </sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >
                    {{ plan.balance }}
                  </span>
                  <sub
                    class="pricing-duration text-body font-medium-1 font-weight-bold"
                  >
                    /{{ $t('past years balance') }}
                  </sub>
                </div>
              </div>

              <div class="d-inline-block">
                {{ $t('Subscription End') }}: <strong>{{ shortDate(plan.subscriptionEndDate) }}</strong>
              </div>

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                :variant="
                  plan.subscriptionActive
                    ? 'outline-success'
                    : 'outline-primary'
                "
                :to="{name: 'payment', params: { amount: getTotalAmount(plan), subscription: true}}"
                block
                class="mt-2"
              >
                {{ $t('Renew') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BButton } from 'bootstrap-vue'

import axios from '@/libs/axios'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      subscriptionType: 'yearly',
      plan: {},
    }
  },
  computed: {
    pricingCols() {
      return 6
    },
  },
  created() {
    axios.get(`user-groups/account-subscription`).then((response) => {
      this.plan = response.data
    })
  },
  methods: {
    getTotalAmount(plan) {
      return plan.subscriptionAmount + plan.balance
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
